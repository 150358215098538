<template>
  <div class="container">
    <div class="backBox">
      <el-row :gutter="10">
        <el-col :span="0.5">
          <img src="../../assets/images/Examine_img/back.png" alt @click="handleBack" />
          <span @click="handleBack">返回</span>
        </el-col>
        <el-col :span="0.5">
          <el-button class="el-icon-document-add" type="primary">待保存</el-button>
        </el-col>
        <el-col :span="0.5">
          <el-button class="el-icon-circle-check" type="primary" @click="handleExamine">完成检查</el-button>
        </el-col>
        <el-col :span="0.5">
          <el-button class="el-icon-tickets" type="primary">查看报告</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData.slice(start, end)"
      tooltip-effect="dark"
      style="width: 100%"
      :height="elementHeight"
      @selection-change="handleSelectionChange"
      highlight-current-row
      @row-click="rowClick"
      @select="selectChange"
      @select-all="selectAll"
      :cell-style="cellStyle"
      @cell-dblclick="cellDblclick"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column width="55">
        <template slot-scope="scope" v-if="scope.row.typeNo == 'P6' && auditType == 1">
          <i class="el-icon-plus" @click="handelScore(scope.row)"></i>
        </template>
      </el-table-column>
      <el-table-column prop="typeInfo" label="类型信息"></el-table-column>
      <el-table-column prop="typeNo" label="阶段"></el-table-column>
      <el-table-column prop="typeNumber" label="NO"></el-table-column>
      <el-table-column prop="elementName" label="问题描述" width="500" show-overflow-tooltip></el-table-column>
      <el-table-column prop="checkStatus" label="评分" v-if="auditType == 0">
        <template slot-scope="scope">
          <div class="ball">
            <div @click="clickColour('r',scope.row)">
              <span :class="scope.row.checkStatus == 'r'?'redBall':''"></span>
              <span>红</span>
            </div>
            <div @click="clickColour('y',scope.row)">
              <span :class="scope.row.checkStatus == 'y'?'yellowBall':''"></span>
              <span>黄</span>
            </div>
            <div @click="clickColour('g',scope.row)">
              <span :class="scope.row.checkStatus == 'g'?'greenBall':''"></span>
              <span>绿</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="评分" v-else>
        <template slot-scope="scope">
          <div v-if="productStatus == 0 && (scope.row.typeNo == 'P3' || scope.row.typeNo == 'P4')">
            <el-select
              v-model="scope.row.processDesign"
              placeholder="请选择"
              style="width:100%"
              @change="changeScore('processDesign',$event,scope.row)"
            >
              <el-option
                v-for="item in (scope.row.isPoint == 1? options1:options)"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div
            v-else-if="productStatus == 1 && (scope.row.typeNo == 'P3' || scope.row.typeNo == 'P4')"
          >
            <el-select
              v-model="scope.row.processDesign"
              placeholder="请选择"
              style="width:50%"
              @change="changeScore('processDesign',$event,scope.row)"
            >
              <el-option
                v-for="item in (scope.row.isPoint == 1? options1:options)"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
            <el-select
              v-model="scope.row.productDesign"
              placeholder="请选择"
              style="width:50%"
              @change="changeScore('productDesign',$event,scope.row)"
            >
              <el-option
                v-for="item in (scope.row.isPoint == 1? options1:options)"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div v-else-if="scope.row.typeNo != 'P6'">
            <el-select
              v-model="scope.row.auditResult"
              placeholder="请选择"
              style="width:100%"
              @change="changeScore('AuditResult',$event,scope.row)"
            >
              <el-option
                v-for="item in (scope.row.isPoint == 1? options1:options)"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="auditResult" label="审核结果">
        <template slot-scope="scope">
          <el-input
            v-if="AreaAudit.id === scope.row.id && columnID === scope.column.id"
            v-model="scope.row.remark"
            @focus="compare(scope.row.remark)"
            @blur="Updata(scope.row, scope.row.remark)"
          ></el-input>
          <span v-else>{{scope.row.remark}}</span>
        </template>
      </el-table-column>
      <el-table-column label="上传图片" width="200">
        <template slot-scope="scope">
          <div class="img_box" @click="openImg(scope.row)">
            <img src="../../assets/images/Examine_img/add.png" alt />
            <span v-show="scope.row.imageCount === 0">请添加图片</span>
            <span v-show="scope.row.imageCount > 0">({{ scope.row.imageCount }})</span>
            <img src="../../assets/images/Examine_img/picture.png" alt class="prcImg" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="检查准则">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            @click="handleSee(scope.row)"
            style="border-radius: 15px !important;"
          >点击查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"></el-button>
          <!-- <el-button size="mini" type="text" icon="el-icon-edit" @click="handleEdit(scope.row)"></el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
      class="_Pagination"
    ></el-pagination>
    <!-- 检查准则弹框 -->
    <el-dialog title="检查准则" :visible.sync="dialogTableVisible" width="1100px">
      <el-table
        :data="recordData"
        border
        stripe
        class="table"
        :span-method="objectSpanMethod"
        :cell-style="tableBackground"
      >
        <el-table-column prop="description" label="问题描述" width="200"></el-table-column>
        <el-table-column prop="standard" label="评分标准" width="400"></el-table-column>
        <el-table-column prop="recordType" label="记录类型"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 上传查看图片弹框 -->
    <el-dialog title="查看/添加图片" :visible.sync="dialogVisibleimg" width="40%">
      <div>添加图片</div>
      <el-upload
        action=" https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        ref="upload"
        :on-preview="handlePictureCardPreview"
        :http-request="uploadFile"
        :headers="headers"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div>已添加图片</div>
      <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px; margin-right: 3px"
          :src="item"
          :preview-src-list="srcList"
          @mouseover="overimg(index)"
        ></el-image>
        <i class="el-icon-delete-solid" v-show="index === Curindex" @click="delImg(item)"></i>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toPostImg">确 定</el-button>
        <el-button @click="dialogVisibleimg = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 新增修改弹框 -->
    <!-- <el-dialog :title="title" :visible.sync="dialogVisible" width="478px">
      <el-form ref="form" :model="form" label-width="auto" class="addForm">
        <el-form-item label="工艺设计" v-if="form.typeNo == 'p3,p4'">
          <el-select v-model="form.processDesign" placeholder="请选择" style="width:100%">
            <el-option
              v-for="item in (form.isPoint == 1? options1:options)"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品设计" v-if="form.typeNo == 'p3,p4'">
          <el-select v-model="form.productDesign" placeholder="请选择" style="width:100%">
            <el-option
              v-for="item in (form.isPoint == 1? options1:options)"
              :key="item.value"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核结果" v-if="form.typeNo != 'p6'">
          <el-input v-model="form.auditResult" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>-->
    <!-- p6打分弹框 -->
    <el-dialog title="P6评分" :visible.sync="dialogScore" width="500px">
      <el-table :data="scoreData" border stripe class="table" :cell-style="tableBackground">
        <el-table-column prop="processName" label="工序名称"></el-table-column>
        <el-table-column prop="processNo" label="工序编号"></el-table-column>
        <el-table-column prop="auditResult" label="评分">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.auditResult"
              placeholder="请选择"
              style="width:100%"
              @change="changeGrade(scope.row)"
            >
              <el-option
                v-for="item in (scope.row.isPoint == 1? options1:options)"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, deleteMethod, put, baseURL } from "../../api/http.js";
import { compressImage } from "../../modules/CompressImage";
import imgTypeChange from "../../modules/imgChange";
export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      multipleSelection: [],
      headers: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
      BUrl: baseURL[process.env.NODE_ENV + ""],
      dialogVisibleimg: false,
      srcList: [],
      // 查询参数
      start: 0,
      end: 10,
      currentPage: 1,
      pageSize: 10,
      count: 0,
      auditType: this.$route.query.auditType,
      PlanId: this.$route.query.planId,
      productStatus: this.$route.query.productStatus,
      dialogTableVisible: false,
      recordData: [
        {
          description: "描述",
          standard: "标准",
          recordType: "类型",
        },
        {
          description: "描述",
          standard: "标准",
          recordType: "类型",
        },
        {
          description: "描述",
          standard: "标准",
          recordType: "类型",
        },
        {
          description: "描述",
          standard: "标准",
          recordType: "类型",
        },
        {
          description: "描述",
          standard: "标准",
          recordType: "类型",
        },
      ],
      Cid: null,
      Curindex: "",
      rowId: null,
      title: "",
      form: {},
      dialogVisible: false,
      options: [
        {
          value: "0",
        },
        {
          value: "4",
        },
        {
          value: "6",
        },
        {
          value: "8",
        },
        {
          value: "10",
        },
        {
          value: "n.a.",
        },
      ],
      options1: [
        {
          value: "0",
        },
        {
          value: "4",
        },
        {
          value: "6",
        },
        {
          value: "8",
        },
        {
          value: "10",
        },
      ],
      columnID: "",
      AreaAudit: {},
      remark: "",
      dialogScore: false,
      scoreData: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let aside = document.querySelector(".aside");
    aside.style.display = "none";
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 46 + 48);
      });
    },
    // 获取审核项列表
    getList() {
      let url =
        this.auditType == 0
          ? "/api/VdaCheckplanlistsub?PlanId=" + this.PlanId
          : "/api/VdaCheckplanlist?PlanId=" + this.PlanId;
      get(url).then((res) => {
        this.tableData = res.data;
        this.count = res.count;
      });
    },
    // 预审评分
    clickColour(e, row) {
      this.form = row;
      this.form.checkStatus = e;
      put("/api/VdaCheckplanlistsub", this.form).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 正式审评分
    changeScore(val, e, row) {
      this.form = row;
      if (val == "processDesign") {
        this.form.processDesign = e;
      } else if (val == "productDesign") {
        this.form.productDesign = e;
      } else {
        this.form.auditResult = e;
      }
      put("/api/VdaCheckplanlist", this.form).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 打开p6评分弹框
    handelScore(row) {
      this.dialogScore = true;
      get("/api/VdaChecklistprocess?CheckListId=" + row.id).then((res) => {
        this.scoreData = res.data;
      });
    },
    // p6评分
    changeGrade(row){
      put('/api/VdaChecklistprocess',row).then(res =>{
        if (res.code == 200) {
          this.$message({
            message: "评分成功",
            type: "success",
          });
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 修改按钮
    // handleEdit(row) {
    //   console.log(row);
    //   this.title = "修改";
    //   this.dialogVisible = true;
    //   this.form = row;
    // },
    // 提交按钮
    // submitForm() {
    //   let url =
    //     this.auditType == 0
    //       ? "/api/VdaCheckplanlistsub"
    //       : "/api/VdaCheckplanlist";
    //   put(url, this.form).then((res) => {
    //     if (res.code == 200) {
    //       this.$message({
    //         message: "修改成功",
    //         type: "success",
    //       });
    //       this.dialogVisible = false;
    //     } else {
    //       this.$message.error(res.message);
    //     }
    //   });
    // },
    // 删除按钮
    handleDelete(row) {
      let url =
        this.auditType == 0
          ? "/api/VdaCheckplanlistsub?id=" + row.id
          : "/api/VdaCheckplanlist?id=" + row.id;
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod(url).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 完成检查
    handleExamine() {
      if (this.rowId == null) {
        this.$message({
          message: "请选择需要完成检查的数据！",
          type: "warning",
        });
      } else {
        put("/api/VdaCheckplan/Complete?PlanId=" + this.rowId).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "完成检查",
              type: "success",
            });
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 双击单元格
    cellDblclick(row, column) {
      this.AreaAudit = row;
      this.columnID = column.id;
    },
    compare(e) {
      this.remark = e;
    },
    Updata(row, e) {
      this.columnID = "";
      if (this.remark === e) return;
      let url =
        this.auditType == 0
          ? "/api/VdaCheckplanlistsub"
          : "/api/VdaCheckplanlist";
      put(url, row).then((res) => {
        if(res.code ==200){
          this.$message({
            type: "success",
            message: "修改成功",
          });
        }else{
          this.$message.error(res.message);
        }
      });
    },
    // 单击表格
    rowClick(row) {
      this.rowId = row.planId;
      this.$refs.multipleTable.toggleRowSelection(row);
      this.selectChange(this.multipleSelection);
    },
    // 返回
    handleBack() {
      let aside = document.querySelector(".aside");
      aside.style.display = "block";
      this.$router.go(-1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectChange(selection) {
      if (selection.length > 1) {
        const del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    cellStyle() {
      return "cursor:pointer;";
    },
    openImg(e) {
      this.Cid = e.id;
      this.dialogVisibleimg = true;
      this.getimg();
    },
    // 获取图片
    getimg() {
      let url =
        this.auditType == 0
          ? "/api/VdaChecklistsubImages/"
          : "/api/VdaChecklistImages/";
      this.srcList = [];
      get(url + this.Cid).then((res) => {
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
        // let newTableData = []
        // this.tableData.map(item =>{
        //   newTableData.push(Object.assign(item,{imagesCount:''}))
        // })
        // this.tableData = newTableData
        // this.tableData.map(item =>{
        //   if(item.id == this.Cid){
        //     item.imagesCount = this.srcList.length
        //   }
        // })
        // console.log(this.tableData);
      });
    },
    overimg(index) {
      this.Curindex = index;
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        let url =
          this.auditType == 0
            ? "/api/VdaChecklistsubImages?Id=" + e.id
            : "/api/VdaChecklistImages?Id=" + e.id;
        if (e.picNameAndAddr === item) {
          console.log(e.id);
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod(url).then(() => {
                this.getimg();
              });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    handlePictureCardPreview() {
      // this.dialogVisiblebig = true;
    },
    async uploadFile(e) {
      console.log(e);
      if (e.file) {
        let file = await imgTypeChange(e.file);
        let newfile = await compressImage(file);
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile);
        }
        this.uploadFileReq(newfile);
      }
    },
    uploadFileReq(file) {
      let url =
        this.auditType == 0
          ? "/api/VdaChecklistsubImages"
          : "/api/VdaChecklistImages";
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/VdaUploadImg", formData)
        .then((res) => {
          this.$refs.upload.clearFiles();
          if (res.code == 200) {
            var data = {
              CheckListId: this.Cid,
              ImagesRemark: "",
              ImageAddress: this.BUrl + "/VdaUploads/" + res.value,
            };
            post(url, {
              data: JSON.stringify([data]),
            }).then(() => {
              this.$refs.upload.clearFiles();
              this.getImage();
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    getImage() {
      let url =
        this.auditType == 0
          ? "/api/VdaChecklistsubImages/"
          : "/api/VdaChecklistImages/";
      get(url + this.Cid).then((res) => {
        this.srcList = [];
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    toPostImg() {
      this.dialogVisibleimg = false;
      this.$refs.upload.clearFiles();
    },
    // 检查准则
    handleSee(row) {
      console.log(row);
      this.dialogTableVisible = true;
    },
    tableBackground(cell) {
      document.getElementsByClassName(cell.column.id)[0].style.background =
        "#E8EBF4";
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: this.recordData.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.start = (this.currentPage - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.pageSize;
      this.end = this.start + this.pageSize;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  .backBox {
    margin-bottom: 16px;
    .el-row {
      display: flex;
      align-items: center;
      img {
        vertical-align: middle;
      }
      span {
        font-size: 14px;
        font-weight: bold;
        color: #3d3d3d;
        margin-left: 7px;
      }
    }
  }
  .addForm {
    .el-form-item {
      margin-bottom: 10px !important;
      margin-right: 24px;
    }
    ::v-deep .el-input__inner {
      height: 40px !important;
    }
  }
  .ball {
    display: flex;
    justify-content: center;
    & > div > span:nth-child(1) {
      vertical-align: middle;
      background: #4b4b4b;
      border: 1px solid #4b4b4b;
    }
    span {
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 5px;
    }
    .yellowBall {
      vertical-align: middle;
      background: #f6ff00 !important;
      border: 1px solid #b8b80f !important;
    }
    .greenBall {
      vertical-align: middle;
      background: #00b050 !important;
      border: 1px solid #0f6b39 !important;
    }
    .redBall {
      vertical-align: middle;
      background: #ff0000 !important;
      border: 1px solid #ff0000 !important;
    }
  }
  ._Pagination {
    text-align: end;
    padding: 10px 20px;
    background-color: #ffffff;
  }
  .table {
    ::v-deep .el-table__row td {
      border-bottom: 1px solid #e8e9ebdb !important;
    }
  }
  .img_box {
    height: 30px;
    background: #ffffff;
    border: 1px dashed #e0e0e0;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
    color: #999999;
    display: flex;
    justify-content: space-between;
    img {
      width: 15px;
      margin: 0 10px;
    }
    span{
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
  .ImgDel {
    position: relative;
  }

  .ImgDel i {
    transform: scale(1.5);
    position: absolute;
    top: -80px;
    right: 7px;
    cursor: pointer;
  }
}
</style>